export const getApiData = (data, environment) => {
	const tempObj = {
		status: data.value?.status ? data.value?.status : null,
		proxy: data,
	}

	if (data.value?.data) {
		tempObj.data = data.value.data
	} else if (Array.isArray(data.value)) {
		tempObj.data = data.value
	} else if (typeof data.value === 'object' && data.value.success) {
		// store the success message
		tempObj.data = data.value
	} else {
		tempObj.data = []
	}

	if (environment && environment !== 'production' && data.value?.debug) {
		tempObj.debug = data.value.debug
	}

	return tempObj
}

export const getAuData = (data, environment) => {
	const tempObj = {
		data: data.value ? data.value : [],
		status: data.value?.status ? data.value?.status : null,
		proxy: data,
	}

	if (environment && environment !== 'production' && data.value?.debug) {
		tempObj.debug = data.value.debug
	}

	return tempObj
}

export const parseErrors = (data) => {
	const errors = []

	for (const error of Object.entries(data)) {
		if (typeof error === 'object') {
			for (const subError of Object.entries(error)) {
				if (typeof subError === 'string') {
					errors.push(subError)
				}
				if (
					Array.isArray(subError) &&
					typeof subError[0] === 'string'
				) {
					errors.push(subError[0])
				}
			}

			continue
		}
		if (typeof error === 'string') {
			errors.push(error)
		}
		if (Array.isArray(error) && typeof error[0] === 'string') {
			errors.push(error[0])
		}
	}
	return errors
}

export const getApiError = (error) => {
	if (error.value?.data || error.value?.statusCode) {
		const err = new Error('error')

		err.status = error.value.statusCode

		// The API is sending back a mixture of responses in errors - sometimes an array, other times a string message
		// we're switching neatly here to avoid differences inside the codebase.
		if (error.value.data?.errors) {
			if (
				typeof yourVariable === 'object' ||
				Array.isArray(error.value.data.errors)
			) {
				err.errors = parseErrors(error.value.data.errors)
			} else {
				err.errors = error.value.data.errors
				err.message = error.value.data.errors
			}
		} else {
			err.message = error.value.message ? error.value.message : null
		}

		err.proxy = error

		return err
	}

	return null
}

export const getAuError = (error) => {
	if (error.value || error.value?.statusCode) {
		const err = new Error('error')

		err.status = error.value.statusCode
		err.message = error.value.message ? error.value.message : null
		err.errors = error.value.data
		err.proxy = error

		return err
	}

	return null
}

export const augmentCarbarUrl = (url, environment) => {
	let configuredUrl = url
	// we aren't using import.meta.env.DEV as we need to enable this for local docker-containers which run
	// a build.
	if (environment === 'local') {
		// TODO - extend to support trace & profiling if needed

		let xdebugDebugCookie = null
		if (import.meta.server) {
			// the server does not have access to the useCookie logic, so we have to force it "on"
			xdebugDebugCookie = 'enabled'
		} else {
			xdebugDebugCookie = useCookie('XDEBUG_SESSION')
		}

		if (xdebugDebugCookie) {
			// add in the xdebug querystring parameter to start debugging. It's assumed this is
			// triggered by one of the xdebug brower plugins.
			// we are doing it via the URL rather than cookies for reliability and simplicitiy
			if (url.indexOf('?') > 0) {
				configuredUrl = `${configuredUrl}&XDEBUG_SESSION_START=${xdebugDebugCookie}`
			} else {
				configuredUrl = `${configuredUrl}?XDEBUG_SESSION_START=${xdebugDebugCookie}`
			}
		}
	}

	return configuredUrl
}
