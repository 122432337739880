import Vehicle from '../models/Vehicle'

export default definePayloadPlugin(() => {
	definePayloadReducer(
		'Vehicle',
		(data) => data instanceof Vehicle && data.toJSON()
	)

	definePayloadReviver('Vehicle', (data) => data)
})
