import validate from "/codebuild/output/src3368516137/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import enforce_45lowercase_45global from "/codebuild/output/src3368516137/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/middleware/enforce-lowercase.global.js";
import redirect_45trailing_45slash_45global from "/codebuild/output/src3368516137/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/middleware/redirect-trailing-slash.global.js";
import manifest_45route_45rule from "/codebuild/output/src3368516137/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  enforce_45lowercase_45global,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}