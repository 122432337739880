import VueScrollTo from 'vue-scrollto'

export const formatCurrency = (value, decimal = false, config = {}) => {
	const fraction = decimal ? 2 : 0
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		// this needs to be USD as AUD will be displayed as A$
		currency: 'USD',
		minimumFractionDigits: fraction,
		maximumFractionDigits: fraction,
		...config,
	})
	return formatter.format(
		typeof value !== 'number' ? parseFloat(value) : value
	)
}

export const roundNumber = (value) =>
	Math.round(typeof value !== 'number' ? Number(value) : value)

export const formatOdometer = (value) => {
	const formatter = new Intl.NumberFormat()
	return formatter.format(
		typeof value !== 'number' ? parseFloat(value) : value
	)
}

export const formatLocation = (value) => {
	const formatter = value.split(',').join(', ')
	return formatter
}

export const formatNumberToPercentage = (value) => {
	if (typeof value === 'number' && value >= 1 && value <= 100) {
		return `${value}%`
	}
	throw new Error('Percentage number must be between 1 and 100')
}

export const scrollToTop = (speed = 500, options = {}) => {
	VueScrollTo.scrollTo('body', speed, {
		...options,
	})
}

export const scrollTo = (element = 'body', speed = 500, options = {}) => {
	VueScrollTo.scrollTo(element, speed, options)
}

export const scrollModalToTop = () => {
	const modalTarget = document.getElementsByClassName('modal-form')
	setTimeout(() => {
		modalTarget[0].scroll({
			top: 0,
			behavior: 'smooth',
		})
	}, 200)
}

export const updateOrCreateHeadLink = (rel, urlSuffix) => {
	const existing = document.querySelector(`link[rel=${rel}]`)

	const runtimeConfig = useRuntimeConfig()

	if (existing) {
		existing.setAttribute(
			'href',
			`${runtimeConfig.public.APP_URL}${urlSuffix}`
		)
	} else {
		useHead({
			link: [
				{
					rel,
					href: `${runtimeConfig.public.APP_URL}${urlSuffix}`,
				},
			],
		})
	}
}

// Returns a random Universally Unique Identifier (UUID) for
export const getRandomUuidForDomIDs = (pattern) => {
	// Accept any desired pattern. If no pattern is provided
	// default to a RFC4122 UUID pattern.
	const _pattern = pattern ?? 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

	const replacePattern = (c) => {
		// Random hexadecimal number
		// eslint-disable-next-line security-node/detect-insecure-randomness
		const r = (Math.random() * 16) | 0

		// If 'x' return hexadecimal number,
		// if 'y' return [8-11] randomly
		const v = c === 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	}

	// Replace each character in the pattern
	// leaving any non x|y character alone.
	return _pattern.replace(/[xy]/g, replacePattern)
}

/**
 * Simple function which allows you to request an object which may take a little time to be set up - e.g. window.braze
 *
 * Use by providing the object you wish to request in function to check and get a promise in return, e.g.
 * pollObjectUntilReady(() => window.braze).then((braze) => { if(braze) { ..do something.. } })
 *
 * timeout defaults to 200, iterations to 20 (meaning it'll poll for 4 seconds and then stop by default)
 */
export const pollFunctionUntilReady = (
	functionToCheck,
	timeout,
	iterations
) => {
	const sleep = (ms) =>
		new Promise((resolve) => {
			setTimeout(resolve, ms)
		})
	let loopCounter = 0

	const waitFor = async function waitFor(functionToWaitFor) {
		// eslint-disable-next-line no-constant-binary-expression
		while ((loopCounter < iterations ?? 20) && !functionToWaitFor()) {
			loopCounter += 1
			await sleep(timeout ?? 200)
		}
		return functionToWaitFor()
	}

	return waitFor(functionToCheck)
}

// sizes are specified in tailwind config
export const isMobileView = () => window?.screen?.width < 768
export const isTabletView = () =>
	window?.screen?.width >= 768 && window.screen?.width < 1024
export const isDesktopView = () =>
	window?.screen?.width >= 1024 && window?.screen?.width < 1280
export const isLargeDesktopView = () => window?.screen?.width >= 1280

/**
 * Navigate the user to the defaaultRedirection, but respect the _redirect querystring value
 */
export const navigateToWithOverride = (
	defaultRedirection,
	options,
	override
) => {
	const route = useRoute()

	// if the redirection contains a /, we can assume it is a direct path to redirect to
	// otherwise, we're likely dealing with a base64 encoded path
	const translateRedirection = (path) =>
		path.includes('/') ? path : atob(path)

	if (override) {
		return navigateTo(translateRedirection(override))
	}

	if (typeof route.query._redirect !== 'undefined' && route.query._redirect) {
		return navigateTo(translateRedirection(route.query._redirect))
	}

	return navigateTo(defaultRedirection, options ?? {})
}
