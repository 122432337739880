import VueTippy from 'vue-tippy'

export default defineNuxtPlugin({
	name: 'tippy-plugin',
	parallel: true,
	setup(nuxtApp) {
		nuxtApp.vueApp.use(
			VueTippy,
			// optional
			{
				directive: 'tippy', // => v-tippy
				component: 'tippy', // => <tippy/>
				componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
				defaultProps: {
					theme: 'light-border',
					placement: 'auto-end',
					allowHTML: true,
				}, // => Global default options * see all props
			}
		)
	},
})
