import { Model } from 'vue-api-query'

export default defineNuxtPlugin({
	name: 'sentry-plugin',
	parallel: true,
	setup() {
		// this has to be assigned a value, but it doesn't have to be a valid http object :)
		// we're overwriting the behaviour in our Model/Model.js request function which uses useFetchForApi
		Model.$http = { aKey: 'aValue' }
	},
})
