export default defineNuxtRouteMiddleware((to) => {
	const { path, query, hash } = to

	if (
		!path.startsWith('/email-validation') &&
		!path.startsWith('/forgot-password') &&
		!path.startsWith('/reset-password') &&
		!path.startsWith('/secure') &&
		!path.startsWith('/sign-up') &&
		path.match(/[A-Z]/g)
	) {
		const nextPath = path.toLowerCase()
		const nextRoute = { path: nextPath, query, hash }
		return navigateTo(nextRoute, { redirectCode: 301 })
	}

	return null
})
