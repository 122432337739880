<template>
	<NuxtLayout>
		<div class="bg-gray-50">
			<Html>
				<Head>
					<Title>
						Subscribe or Buy Your Next Car | carbar Australia
					</Title>
					<Meta
						name="description"
						content="Buy, finance or subscribe to your next car online. Sell your car without haggling, get an instant valuation."
					/>
				</Head>
			</Html>

			<DefaultSection>
				<div class="mt-20 flex flex-col items-center">
					<h1>{{ error.statusCode ?? '404' }}</h1>
					<div class="text-xl text-gray-600">
						{{
							error.statusMessage ??
							"Oops. We can't seem to find the page you are looking for."
						}}
					</div>
					<div class="mb-40 mt-7">
						<CarbarButton
							background-color="bg-orange-400"
							font-weight="font-medium"
							text-color="text-white"
							rounded="rounded"
							@click="handleError()"
						>
							Back to home
						</CarbarButton>
					</div>
				</div>
			</DefaultSection>
		</div>
	</NuxtLayout>
</template>

<script setup>
import { clearError } from 'nuxt/app'
import DefaultSection from '@/components/sections/DefaultSection.vue'
import CarbarButton from '@/components/buttons/CarbarButton.vue'

const emits = defineEmits(['click'])

const props = defineProps({
	error: Object,
})

const handleError = () => clearError({ redirect: '/' })
</script>
