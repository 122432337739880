import { acceptHMRUpdate, defineStore } from 'pinia'
import { useFetchForCarbarAu } from '../composables/useFetchForCarbarAu'

const expiryDate = new Date()
expiryDate.setFullYear(expiryDate.getFullYear() + 1)

// @ts-ignore
export const useCarbarAuAuthStore = defineStore('carbarAuAuth', {
	namespaced: true,
	state: () => ({
		user: null,
		redirect: null,
	}),
	getters: {
		isLoggedIn: (state) => state.user && state.user.id,
		isNovated: (state) => state.user && state.user.isNovated,
		accessToken: (state) =>
			state.user ? state.user.personalAccessToken : null,
		firstName: (state) => (state.user ? state.user.firstName : ''),
		lastName: (state) => (state.user ? state.user.lastName : ''),
	},
	actions: {
		async signIn(email, password, recaptcha, enableRedirect = true) {
			await nextTick()

			const runtimeConfig = useRuntimeConfig()

			// we aren't running a try/catch here as we want to pass errors straight back to code calling this function
			const { data } = await useFetchForCarbarAu(`/secure/authenticate`, {
				method: 'POST',
				body: { email, password, _recaptcha: recaptcha },
			})

			this.user = {
				id: data.id,
				uuid: data.uuid,
				firstName: data.firstname,
				lastName: data.lastname,
				personalAccessToken: data.personalAccessToken,
				isNovated: data.isNovated,
				cookieVersion: runtimeConfig.public.AUTH_COOKIE_VERSION,
			}

			if (enableRedirect === false) {
				return
			}

			const { redirect } = this
			this.redirect = null

			navigateToWithOverride('/account', { external: true }, redirect)
		},
		async signOut() {
			await Promise.all([
				useFetchForCarbarAu('/logout'),
				(this.user = null),
			])
		},
		async resendVerificationEmail(email) {
			const { data } = await useFetchForApi(`/resend-validation-email`, {
				method: 'POST',
				body: { email },
			})

			return data.success
		},
		storeRedirect(redirect) {
			this.redirect = redirect
		},
		removeRedirect() {
			this.redirect = null
		},
	},
	persist: {
		storage: piniaPluginPersistedstate.cookies({
			sameSite: 'strict',
			expires: expiryDate,
		}),
	},
})

if (import.meta.hot) {
	import.meta.hot.accept(
		acceptHMRUpdate(useCarbarAuAuthStore, import.meta.hot)
	)
}
