// plugins/sentry.client.ts
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
	name: 'sentry-plugin',
	parallel: true,
	setup(nuxtApp) {
		const { vueApp } = nuxtApp

		const runtimeConfig = useRuntimeConfig()

		if (runtimeConfig.public.sentry.disabled) {
			return
		}

		const router = useRouter()

		Sentry.init({
			app: [vueApp],
			dsn: runtimeConfig.public.sentry.dsn,
			environment: runtimeConfig.public.sentry.env,
			integrations: [
				Sentry.browserTracingIntegration({ router }),
				Sentry.replayIntegration(),
			],
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,

			beforeSend(event, hint) {
				// Check if it is an exception, and if so, log it.
				if (event.exception) {
					console.error(
						`[Exeption handled by Sentry]: (${hint.originalException})`,
						{ event, hint }
					)
				}
				// Continue sending to Sentry
				return event
			},
		})

		vueApp.mixin(
			Sentry.createTracingMixins({
				trackComponents: true,
				timeout: 2000,
				hooks: ['activate', 'mount', 'update'],
			})
		)
		Sentry.attachErrorHandler(vueApp, {
			logErrors: false,
			attachProps: true,
			trackComponents: true,
			timeout: 2000,
			hooks: ['activate', 'mount', 'update'],
		})
	},
})
