import qs from 'qs'

export default {
	parseQuery: qs.parse,
	stringifyQuery: (q) => qs.stringify(q, { encode: false }),
	scrollBehavior: (to, savedPosition) =>
		new Promise((resolve) => {
			setTimeout(() => {
				if (to.hash && to.hash === '#subscribe-now') {
					resolve(savedPosition)
				} else if (to.hash) {
					resolve({
						el: to.hash,
						behavior: 'smooth',
					})
				} else {
					resolve({ left: 0, top: 0 })
				}
			}, 300)
		}),
}
