import { useCarbarAuAuthStore } from '@/stores/carbar-au-auth'

export const isLoggedIn = () => {
	const auUser = useCarbarAuAuthStore()
	return auUser.isLoggedIn
}

export const checkAuthLoginVersion = () => {
	const auUser = loggedInUser()

	if (auUser) {
		const runtimeConfig = useRuntimeConfig()

		if (
			!auUser.cookieVersion ||
			auUser.cookieVersion !== runtimeConfig.public.AUTH_COOKIE_VERSION
		) {
			// signOut doesn't trigger any redirects - just silently logs the user out
			signOut()
		}
	}
}

export const loggedInUser = () => {
	if (!isLoggedIn()) {
		return null
	}

	const auUser = useCarbarAuAuthStore()
	return auUser.user
}

export const isNovatedUser = () => {
	const auUser = useCarbarAuAuthStore()
	return auUser.isNovated
}

export const signOut = async () => {
	const auUser = useCarbarAuAuthStore()
	await auUser.signOut()
}

export const apiAccessToken = () => {
	if (isLoggedIn()) {
		const auUser = useCarbarAuAuthStore()
		return auUser.accessToken
	}

	if (import.meta.server) {
		return process.env.NUXT_PUBLIC_BEARER_TOKEN
	}

	const runtimeConfig = useRuntimeConfig()
	return runtimeConfig.public.BEARER_TOKEN
}

export const storeUserRedirect = (redirect) => {
	const auUser = useCarbarAuAuthStore()
	auUser.storeRedirect(redirect)
}

export const getUserRedirect = () => {
	const auUser = useCarbarAuAuthStore()
	return auUser.redirect
}

export const removeUserRedirect = () => {
	const auUser = useCarbarAuAuthStore()
	auUser.removeRedirect()
}

export const hasUserRedirect = () => !!getUserRedirect()
