<template>
	<button
		:class="[
			isOutline
				? isMixed
					? customOutlineColor
					: outlineColor
				: backgroundColor,
			isOutline
				? isMixed
					? 'text-orange-400 hover:text-white'
					: textColor
				: 'text-white',
			isOutline ? 'font-normal' : fontWeight,
			fontSize,
			lineHeight,
			border,
			borderColor,
			isFullWidth ? width : minWidth,
			isOutline ? 'shadow-none' : shadow,
			rounded,
			paddingY,
			paddingX,
			size === 3 ? 'px-4 py-2' : '',
			size === 4 ? 'px-4 py-3' : '',
			size === 5 ? 'px-4 py-3 sm:px-5 sm:py-4' : '',
			size === 6 ? 'px-6 py-4' : '',
			fixedHeight && size === 3 ? 'h-9' : '',
			fixedHeight && size === 4 ? 'h-10' : '',
			fixedHeight && size === 5 ? 'h-auto min-h-[3rem]' : '',
		]"
		:disabled="disabled"
		class="relative transition-all ease-in focus:outline-none disabled:bg-gray-200 disabled:text-gray-600 disabled:shadow-none"
		v-bind="$attrs"
		@click="onClick"
	>
		<div
			v-if="disabled && showLoader"
			class="absolute left-1/2 top-1/2 flex h-6 w-6 -translate-x-1/2 -translate-y-1/2 justify-center align-middle"
		>
			<IconsIconLoading />
		</div>
		<slot>Button</slot>
	</button>
</template>

<script setup>
const props = defineProps({
	backgroundColor: {
		type: [Boolean, String, Array],
		default: 'bg-orange-400 hover:bg-orange-600',
		required: false,
	},
	isOutline: {
		type: Boolean,
		default: false,
		required: false,
	},
	isMixed: {
		type: Boolean,
		default: false,
		required: false,
	},
	customOutlineColor: {
		type: String,
		default:
			'bg-transparent border border-orange-400 text-orange-400 hover:text-white hover:bg-orange-400',
		required: false,
	},
	outlineColor: {
		type: String,
		default:
			'bg-transparent border border-gray-400 hover:border-orange-400',
		required: false,
	},
	textColor: {
		type: [Boolean, String, Array],
		default: 'text-gray-600 hover:text-orange-400',
		required: false,
	},
	fontWeight: {
		type: String,
		default: 'font-medium',
		required: false,
	},
	fontSize: {
		type: String,
		default: 'text-base',
		required: false,
	},
	border: {
		type: [Boolean, String, Array],
		default: 'border',
		required: false,
	},
	borderColor: {
		type: String,
		default: 'border-gray-400',
		required: false,
	},
	shadow: {
		type: String,
		default: 'shadow-md',
		required: false,
	},
	rounded: {
		type: String,
		default: 'rounded',
		required: false,
	},
	isFullWidth: {
		type: Boolean,
		default: true,
		required: false,
	},
	width: {
		type: String,
		default: 'w-full',
		required: false,
	},
	minWidth: {
		type: String,
		// default: 'min-w-45 lg:min-w-45',
		default: 'w-full md:w-auto',
		required: false,
	},
	lineHeight: {
		type: String,
		// it's mostly one liner text so let's keep the default as line-height: 1
		default: 'leading-none',
		required: false,
	},
	paddingX: {
		type: String,
		default: '',
		required: false,
	},
	paddingY: {
		type: String,
		default: '',
		required: false,
	},
	fixedHeight: {
		type: Boolean,
		default: true,
		required: false,
	},
	size: {
		type: Number,
		default: 5,
		required: false,
		validator: (value) => [3, 4, 5, 6].includes(value),
	},
	showLoader: {
		type: Boolean,
		default: false,
		required: false,
	},
	disabled: {
		type: Boolean,
		default: false,
		required: false,
	},
})

const emit = defineEmits(['click'])

const onClick = () => {
	emit('click')
}
</script>
