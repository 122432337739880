import PressRelease from '../models/PressRelease'

export default definePayloadPlugin(() => {
	definePayloadReducer(
		'PressRelease',
		(data) => data instanceof PressRelease && data.toJSON()
	)

	definePayloadReviver('PressRelease', (data) => data)
})
